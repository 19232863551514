import Script from 'next/script';

import { checkoutSessionId } from 'consts/api';

export const RiskifiedBeacon = () => (
  <Script data-order-session-id={checkoutSessionId} strategy='lazyOnload' id='riskified-beacon'>
    {/* copied from https://apiref.riskified.com/#beacon */}
    {`(function() {
      function riskifiedBeaconLoad() {
        var store_domain = 'getbezel.com';

        var scriptTag = document.getElementById('riskified-beacon');
        var orderSessionId = scriptTag.getAttribute('data-order-session-id');

        var session_id = orderSessionId;
        var url = ('https:' == document.location.protocol ? 'https://' : 'http://')
          + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = url;
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (window.attachEvent)
        window.attachEvent('onload', riskifiedBeaconLoad)

      else
        window.addEventListener('load', riskifiedBeaconLoad, false);
    })();`}
  </Script>
);
