import 'styles/custom.scss';
import 'styles/typography.scss';
import 'styles/decorations.scss';
import 'styles/globals.css';
import 'styles/layout.scss';
import 'styles/bootstrap-overrides.scss';
import 'styles/css-transitions.scss';

import SEO from '../next-seo.config';
import { useRouter } from 'next/router';
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';
import { DefaultSeo } from 'next-seo';
import { FontGlobals } from 'fonts';

import { CompleteProfileModal } from 'components/common/CompleteProfileModal';
import { Footer } from 'components/common/footer';
import { Loading } from 'components/common/Loading';
import { TopNav } from 'components/common/navbar/TopNav';
import { ToastProvider } from 'components/design-system/Toaster';
import { RootProviders } from 'components/providers/RootProviders';
import { SellersFooter } from 'components/sellers/common/SellersFooter';
import { COLORS } from 'consts';
import { useBezelUser } from 'context/bezelUser';
import { useBranding } from 'context/branding';
import { useInitAnalytics, useInitIntercom, useInitTagManager, useSendUserTimezone } from 'hooks';
import { useInitEquinox } from 'hooks/useInitEquinox';
import { isDevDomain } from 'lib/helpers';
import { RiskifiedBeacon, Signifyd } from 'lib/scripts';
import { MyAppProps, RootProps } from 'types/app';

const progressBarOptions = { showSpinner: false };

const MyApp: React.FC<MyAppProps> = ({ Component, pageProps }) => {
  useInitAnalytics();
  useInitTagManager();
  useInitEquinox();
  useInitIntercom();

  const { isLoading: userIsLoading, user } = useBezelUser();

  useSendUserTimezone(user?.id);

  const router = useRouter();
  const { usePrimaryBranding, isLoading: isBrandingLoading } = useBranding();

  if (isBrandingLoading || userIsLoading) {
    return (
      <div className='w-100 h-100'>
        <Loading isFullSize />
      </div>
    );
  }

  // hide TopNav on custom seller sites
  const showTopNav = !router.pathname.includes('/not-found') && usePrimaryBranding;
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <>
      {isDevDomain ? (
        <DefaultSeo dangerouslySetAllPagesToNoFollow dangerouslySetAllPagesToNoIndex />
      ) : (
        <DefaultSeo {...SEO} />
      )}
      <Signifyd />
      <RiskifiedBeacon />
      <FontGlobals />
      <ProgressBar color={COLORS.lightGreen} options={progressBarOptions} />
      <div className='w-100'>
        {showTopNav && <TopNav />}
        {getLayout(<Component {...pageProps} />)}
        {usePrimaryBranding ? <Footer /> : <SellersFooter />}
      </div>
      <CompleteProfileModal />
      <ToastProvider />
    </>
  );
};

const Root = (props: RootProps) => (
  <RootProviders {...props.pageProps}>
    <MyApp {...props} />
  </RootProviders>
);

export default Root;
