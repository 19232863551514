import { CSSProperties, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FaBars } from '@react-icons/all-files/fa6/FaBars';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { Search } from 'components/search/Search';
import { useGetSellerNavBarIsEnabled } from 'components/seller/navigationBar/useGetSellerNavBarIsEnabled';
import { COLORS } from 'consts';
import { useBezelUser } from 'context/bezelUser';
import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import styles from 'styles/TopNav.module.scss';

import { NavbarDropdownDesktop } from './NavbarDropdown';
import { TopNavAuthSection } from './TopNavAuthSection';
import { TopNavCheckout } from './TopNavCheckout';
import { TopNavShopItems } from './TopNavShopItems';
import { TopNavStrip } from './TopNavStrip';

const MOBILE_TOP_NAV_HEIGHT = 125;

export const TopNavTestIds = {
  container: 'top-nav-container'
};

// 1 more than clip_to_bottom className
const containerStyle: CSSProperties = { zIndex: 1021 };

export const TopNav = () => {
  const router = useRouter();
  const { user } = useBezelUser();
  const [scrolling, setScrolling] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [onSearchFocused, setOnSearchFocused] = useState(false);
  const { isTabletOrMobile, isMobileSmall } = useViewport();
  const [navDropdownDesktopIsOpened, setNavDropdownDesktopIsOpened] = useState(false);
  const [navDropdownDesktopWasForcedClosed, setNavDropdownDesktopWasForcedClosed] = useState(false);
  const [minHeight, setMinHeight] = useState<string | number>(0);
  const { isDefault } = useTheme();

  const logoHeight = isMobileSmall ? 30 : 40;

  const sellerTopNavIsEnabled = useGetSellerNavBarIsEnabled();
  const forceClosedTopNavbar = () => setNavDropdownDesktopWasForcedClosed(true);

  const navbarVisible = !router.pathname.includes('/checkout');
  const onNavbarToggle = (expanded: boolean) => setNavbarExpanded(expanded);

  const onLogoClick = () => {
    // Ensure that mobile navbar is collapsed whenever user clicks on logo to return to hompage
    if (isMobileSmall && navbarExpanded) {
      setNavbarExpanded(false);
    }
  };

  useEffect(() => {
    if (user) {
      setCookie('user_id', user.id);
    }
  }, [user]);

  useEffect(() => {
    const onScroll = () => {
      setScrolling(window.pageYOffset > 0);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const callback = () => {
      setMinHeight(isTabletOrMobile ? window.innerHeight - MOBILE_TOP_NAV_HEIGHT : 'auto');
    };

    callback();
    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, [isTabletOrMobile]);

  useEffect(() => {
    document.body.style.overflow = navbarExpanded && isTabletOrMobile ? 'hidden' : 'auto'; // to prevent body scrolling when mobile dropdown is open
  }, [navbarExpanded, isTabletOrMobile]);

  return (
    <div data-testid={TopNavTestIds.container} className='position-sticky top-0 w-100' style={containerStyle}>
      {navbarVisible && (
        <>
          <TopNavStrip />
          <Navbar
            collapseOnSelect
            onToggle={onNavbarToggle}
            expand='lg'
            id='bezel-top-nav'
            style={{ backgroundColor: isDefault ? COLORS.primaryLight : COLORS.primaryDark }}
            className={classNames(
              'py-3 w-100',
              styles.navigation,
              scrolling && styles.fakeshadow,
              isMobileSmall && styles.fakeshadowMobile,
              navbarExpanded && 'shadow'
            )}
            expanded={navbarExpanded}
          >
            <div className='container-fluid p-0 position-relative' style={{ minHeight: `${logoHeight}px` }}>
              <div
                className={classNames(
                  'd-flex flex-nowrap align-items-center justify-content-between flex-grow-1',
                  isTabletOrMobile && 'px-3'
                )}
              >
                {!(onSearchFocused && isMobileSmall) && !sellerTopNavIsEnabled && (
                  <Link href='/' className='d-flex' onClick={onLogoClick}>
                    <CloudinaryImage
                      priority
                      src={isDefault ? 'top-nav-logo.png' : 'logo-with-label-white.png'}
                      width={100}
                      height={logoHeight}
                      alt={'Bezel Logo'}
                      style={{ objectFit: 'contain' }}
                    />
                  </Link>
                )}
                <Search setOnSearchFocused={setOnSearchFocused} onSearchFocused={onSearchFocused} />
              </div>
              {!(onSearchFocused && isMobileSmall) && (
                <Navbar.Toggle
                  aria-controls='responsive-navbar-nav'
                  className={classNames(styles.hamburger, isTabletOrMobile && 'pe-4')}
                >
                  <FaBars size='0.875em' color={isDefault ? undefined : COLORS.primaryLight} />
                </Navbar.Toggle>
              )}
              <Navbar.Collapse
                id='responsive-navbar-nav'
                className={classNames(styles['navbar-collapse'], 'riforma-regular')}
              >
                <Nav
                  style={{ height: minHeight }}
                  className='my-1 ms-auto d-flex flex-column flex-lg-row justify-content-between justify-content-lg-start align-items-lg-center'
                >
                  <TopNavShopItems
                    isTabletOrMobile={isTabletOrMobile}
                    navDropdownDesktopIsOpened={navDropdownDesktopIsOpened}
                    setNavDropdownDesktopIsOpened={setNavDropdownDesktopIsOpened}
                    setNavDropdownDesktopWasForcedClosed={setNavDropdownDesktopWasForcedClosed}
                  />
                  <TopNavAuthSection isTabletOrMobile={isTabletOrMobile} />
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
          <NavbarDropdownDesktop
            onClose={forceClosedTopNavbar}
            isOpen={navDropdownDesktopIsOpened}
            disabled={navDropdownDesktopWasForcedClosed}
          />
        </>
      )}
      {!navbarVisible && <TopNavCheckout scrolling={scrolling} logoWidth={logoHeight} />}
    </div>
  );
};
