import { Nav } from 'react-bootstrap';

import { Link } from 'components/common/Link';
import { useBezelUser } from 'context/bezelUser';
import { pageRoutes } from 'lib/routes';
import { StyledComponent, User } from 'types';

const getSellerLink = (user: User | undefined) => {
  if (user && user.sellerProfile) {
    return user.sellerProfile.live ? pageRoutes.sellerDashboard : pageRoutes.sellerOnboarding;
  }

  return pageRoutes.sellerLanding;
};

export const NavlinkSell: React.FC<StyledComponent> = ({ className }) => {
  const { user } = useBezelUser();

  return (
    // legacyBehavior has to be added because Nav.Link adds an extra a tag
    <Link legacyBehavior passHref href={getSellerLink(user)}>
      <Nav.Link className={className}>Sell</Nav.Link>
    </Link>
  );
};
