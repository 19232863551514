import { memo, useMemo } from 'react';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';
import { StyledComponent } from 'types';
import { getCustomMarkupFormattedText } from 'utils/text-helpers/getCustomMarkupFormattedText';

import { brandItems, priceItems } from './consts';
import { useGetFeatureCollections } from './useGetFeatureCollections';

import { NavbarDropdownItem } from '../NavbarDropdownItem';
import { ShopAllButton } from '../ShopAllButton';

interface NavbarDropdownProps extends StyledComponent {
  onClose: () => void;
  isOpen?: boolean;
  disabled?: boolean;
}

const featuredCollectionsContainerStyle = { maxHeight: '200px' };

export const NavbarDropdownDesktop: React.FC<NavbarDropdownProps> = memo(({ isOpen, onClose, disabled }) => {
  const featuredCollectionsItems = useGetFeatureCollections();
  const [brandItemsCol1, brandItemsCol2] = useMemo(() => chunk(brandItems, brandItems.length / 2 + 1), []);
  const { isDark } = useTheme();

  return (
    <div
      className={classNames(
        'position-absolute w-100 overflow-hidden d-none d-md-block',
        styles['shop-desktop-dropdown'],
        {
          [styles['is-open']]: isOpen,
          [styles['disabled']]: disabled,
          'bg-light': !isDark,
          [styles.bgDarkBlack]: isDark,
          'mt-0': isDark
        }
      )}
    >
      <div className='top-0 w-100 main-content py-5 text-primary'>
        <div className='riforma-regular d-flex justify-content-between fs-14px line-height-24px'>
          <div className='w-40'>
            <div className='text-secondary text-uppercase mb-3'>Brands</div>
            <div className='d-flex justify-content-between'>
              <ul className='list-unstyled mb-0' onClick={onClose}>
                {brandItemsCol1.map(({ brandKey, label }) => (
                  <NavbarDropdownItem key={brandKey} href={pageRoutes.brand(brandKey)}>
                    {label}
                  </NavbarDropdownItem>
                ))}
              </ul>
              <ul className='list-unstyled mb-0' onClick={onClose}>
                {brandItemsCol2.map(({ brandKey, label }) => (
                  <NavbarDropdownItem key={brandKey} href={pageRoutes.brand(brandKey)}>
                    {label}
                  </NavbarDropdownItem>
                ))}
                <ShopAllButton href={pageRoutes.explore()} />
              </ul>
            </div>
          </div>

          <div className='d-flex justify-content-between w-40'>
            <div className='mb-0' onClick={onClose}>
              <div className='text-secondary text-uppercase mb-3'>PRICE</div>
              <ul className='list-unstyled'>
                {priceItems.map(({ label, priceKey }) => (
                  <NavbarDropdownItem key={label} href={pageRoutes.price(priceKey)}>
                    {label}
                  </NavbarDropdownItem>
                ))}
                <ShopAllButton href={pageRoutes.explore()} />
              </ul>
            </div>
            {featuredCollectionsItems && (
              <div className='mb-0' onClick={onClose}>
                <div className='text-secondary text-uppercase mb-3'>FEATURED COLLECTIONS</div>
                <div className='overflow-auto-hidden' style={featuredCollectionsContainerStyle}>
                  <ul className='list-unstyled'>
                    {featuredCollectionsItems.map(item => (
                      <NavbarDropdownItem
                        key={item.name}
                        href={pageRoutes.fullCollectionUrl(item.object.name, item.object.id)}
                      >
                        {getCustomMarkupFormattedText(item.object.formattedTitle)}
                      </NavbarDropdownItem>
                    ))}
                  </ul>
                </div>
                <ul className='list-unstyled'>
                  <ShopAllButton href={pageRoutes.explore()} />
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
